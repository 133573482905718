import styled from "styled-components";
import { Outlet } from "react-router";
import { Normalize } from "styled-normalize";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Rythm, Title } from "./Typography";
import { TextButtonStyle, SyncTextButton } from "./Button";
import { AppConfig } from "./app-config";

const Nav = styled.div`
  display: flex;
  gap: 1em;
`;

const MainWrapper = styled.div`
  font-family: "Roboto Mono";
  padding: 0 1em 2em 1em;
  font-size: 12px;
  color: #444;
`;

export const Shell: React.FunctionComponent<React.PropsWithChildren> = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <style type="text/css">{`
      * {
        box-sizing: border-box;
      }

      body {
        position: relative;
        /* Disable margin collapse */
        padding: 0.05px;
      }

      body::after {
        /* Change the "height" parameter here to match your base line-height. */
        background: url('data:image/svg+xml;utf8,<svg height="32" width="1" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="0.5" style="fill:rgba(0, 255, 255, 0)" /></svg>')
          repeat top left;

        content: "";
        display: block;
        pointer-events: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        position: absolute;
      }

      body:active::after {
        display: none;
      }
      `}</style>
      </Helmet>
      <Normalize />
      <MainWrapper>
        <Rythm height={2}>
          <Title type="h1">DSIT</Title>
        </Rythm>

        <Rythm height={1}>
          <Nav>
            <TextButtonStyle href="/users">Ouihelpers</TextButtonStyle> |{" "}
            <TextButtonStyle href="/zones-and-agencies">Zones & Agences</TextButtonStyle> |{" "}
            <TextButtonStyle href="/postal-codes">Codes postaux</TextButtonStyle>|{" "}
            <TextButtonStyle href="/financial-helps">Aides</TextButtonStyle> |{" "}
            <SyncTextButton text="Se déconnecter" onClick={AppConfig.logout} />
          </Nav>
        </Rythm>

        <Rythm height={1.5} />

        <Outlet />
      </MainWrapper>
    </HelmetProvider>
  );
};

import { Button } from "@/codegen/ui/button";
import {
  PaginationRoot,
  PaginationPrevTrigger,
  PaginationNextTrigger,
} from "@/codegen/ui/pagination";
import { Text, Stack, HStack, Table, Flex, usePaginationContext } from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  useReactTable,
  ColumnDef,
  getSortedRowModel,
  VisibilityState,
  FilterFnOption,
} from "@tanstack/react-table";
import { forwardRef, useEffect, useMemo } from "react";

// Re-implement the PaginationPageText component from the Chakra UI with
// a french text...
const FrenchPaginationPageText = forwardRef<HTMLParagraphElement>(
  function PaginationPageText(props, ref) {
    const { pageRange, count } = usePaginationContext();
    const content = useMemo(() => {
      return `${pageRange.start + 1} - ${pageRange.end} de ${count}`;
    }, [pageRange, count]);

    return (
      <Text fontWeight="medium" ref={ref} {...props}>
        {content}
      </Text>
    );
  }
);

interface CsvMetadataType<T> {
  filename: string;
  columns: {
    header: string;
    getter: (t: T) => string;
  }[];
}

// This is https://stackoverflow.com/a/68146412
function makeCsvContent<T>(data: T[], csvMetadata: CsvMetadataType<T>): string {
  const headers = [csvMetadata.columns.map(c => c.header)];
  const refinedData = data.map(row => csvMetadata.columns.map(c => c.getter(row)));

  return headers
    .concat(refinedData)
    .map(
      row =>
        row
          .map(String) // convert every value to String
          .map(v => v.replaceAll('"', '""')) // escape double quotes
          .map(v => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}

// This is https://stackoverflow.com/a/68146412
function downloadCsv<T>(data: T[], csvMetadata: CsvMetadataType<T>): void {
  const blob = new Blob([makeCsvContent(data, csvMetadata)], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const filenameDatePrefix = new Date()
    .toISOString()
    .substr(0, 16)
    .replaceAll("-", "")
    .replaceAll(":", "")
    .replaceAll("T", "-");

  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", `${filenameDatePrefix}-${csvMetadata.filename}`);
  pom.click();
}

export const TableLayout = function <T>({
  data,
  columns,
  withPagination,
  withSorting = true,
  withGlobalFilter,
  globalFilter,
  globalFilterFn,
  columnVisibility,
  csvMetadata,
  tableRootRef,
}: {
  data: T[];
  columns: ColumnDef<T, any>[];
  withPagination?: boolean;
  withSorting?: boolean;
  withGlobalFilter?: boolean;
  globalFilter?: string | undefined;
  globalFilterFn?: FilterFnOption<T> | undefined;
  columnVisibility?: VisibilityState | undefined;
  csvMetadata?: CsvMetadataType<T> | undefined;
  tableRootRef?: React.RefObject<HTMLTableElement>;
}) {
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...(withPagination ? { getPaginationRowModel: getPaginationRowModel() } : {}),
    ...(withSorting ? { getSortedRowModel: getSortedRowModel() } : {}),
    ...(withGlobalFilter
      ? {
          getFilteredRowModel: getFilteredRowModel(),
          globalFilterFn: globalFilterFn || "includesString",
        }
      : {}),
    // Avoid resetting the page index when the column visibility
    // changes. Forces us to manually reset the page when the filters
    // or the column sorting changes.
    autoResetPageIndex: false,
    defaultColumn: {
      size: -1,
      minSize: -1,
    },
    state: {
      ...(withGlobalFilter && globalFilter ? { globalFilter } : {}),
      ...(columnVisibility ? { columnVisibility } : {}),
    },
    initialState: {
      ...(withPagination
        ? {
            pagination: {
              pageIndex: 0,
              pageSize: 15,
            },
          }
        : {}),
    },
  });

  // Reset pagination when we're given a new global filter.
  useEffect(() => {
    let isMounted = true;
    if (isMounted) table.resetPageIndex();
    return () => {
      isMounted = false;
    };
  }, [globalFilter, table]);

  return (
    <Stack gap={4}>
      <Table.Root
        size="sm"
        ref={tableRootRef}
        // Rounded border styling starts
        rounded="md"
        borderWidth={1}
        borderCollapse={"separate"}
        borderSpacing={0}
        overflow={"hidden"}
        css={{
          "& tr:last-child td": { borderBottom: 0 },
        }}
        // Rounded border styling ends
      >
        <Table.Header>
          {table.getHeaderGroups().map(headerGroup => (
            <Table.Row key={headerGroup.id} backgroundColor={"gray.100"}>
              {headerGroup.headers.map(header => (
                <Table.ColumnHeader
                  key={header.id}
                  style={{ width: header.getSize() }}
                  onClick={e => {
                    const toggleSortingHandler = header.column.getToggleSortingHandler();
                    if (toggleSortingHandler) {
                      toggleSortingHandler(e);
                      table.resetPageIndex();
                    }
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : [
                        flexRender(header.column.columnDef.header, header.getContext()),
                        {
                          asc: " ↓",
                          desc: " ↑",
                        }[header.column.getIsSorted() as string] ?? null,
                      ]}
                </Table.ColumnHeader>
              ))}
            </Table.Row>
          ))}
        </Table.Header>

        <Table.Body>
          {table.getRowModel().rows.map(row => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Table.Cell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      {(withPagination || csvMetadata) && (
        <Flex gap="8" justify="space-between">
          {withPagination && (
            <PaginationRoot
              count={table.getRowCount()}
              pageSize={table.getState().pagination.pageSize}
              page={table.getState().pagination.pageIndex + 1}
              onPageChange={e => table.setPageIndex(e.page - 1)}
            >
              <HStack>
                <FrenchPaginationPageText />
                <PaginationPrevTrigger />
                <PaginationNextTrigger />
              </HStack>
            </PaginationRoot>
          )}

          {csvMetadata && (
            <HStack>
              <Button
                variant="ghost"
                onClick={() =>
                  downloadCsv(
                    table.getFilteredRowModel().rows.map(r => r.original),
                    csvMetadata
                  )
                }
              >
                Exporter en CSV
              </Button>
            </HStack>
          )}

          {withPagination && (
            <HStack>
              {[15, 30, 50].map(size => (
                <Button
                  key={size}
                  variant={table.getState().pagination.pageSize === size ? "outline" : "ghost"}
                  onClick={() => table.setPageSize(size)}
                >
                  {size}
                </Button>
              ))}
            </HStack>
          )}
        </Flex>
      )}
    </Stack>
  );
};

import { useForm, useWatch, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DevTool } from "@hookform/devtools";
import { Container, Stack, Center, Heading, Text, Input, Textarea, Alert } from "@chakra-ui/react";
import { AsyncSelect, MultiValue } from "chakra-react-select";

import { Radio, RadioGroup } from "@/codegen/ui/radio";
import { Field } from "@/codegen/ui/field";
import { Button } from "@/codegen/ui/button";

import { FormStepCustom } from "@/components/2/form";
import { AppConfig, JobType, AgencyOptionType } from "@/components/app-config";
import { improvedFetch } from "@/components/fetch";

import {
  OnboardingSchemaType,
  OnboardingSchema,
  makeMail,
  groupedJobOptionsPromises,
} from "./misc";

const onboardingFetch = (onboardingData: OnboardingSchemaType) => {
  return improvedFetch(`/onboardings`, {
    method: "POST",
    body: JSON.stringify(onboardingData),
  });
};

export const Onboarding = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setError,
  } = useForm<OnboardingSchemaType>({
    resolver: zodResolver(OnboardingSchema),
    defaultValues: {
      withTablet: true,
      withXimi: true,
      withPhone: false,
      withData: false,
      agencies: [],
    },
  });

  const [firstName, lastName, job] = useWatch({
    control,
    name: ["firstName", "lastName", "job"],
  });

  const isJobOther = job === "other";

  return (
    <Container maxW="lg" py="24">
      <Stack gap="8">
        <Stack gap="6">
          <Center fontSize="6xl">➕</Center>

          <Stack gap="3" textAlign="center">
            <Heading size="sm">Onboarding</Heading>
            <Text color="fg.muted">
              Demande de création des comptes et de préparation du matériel pour un nouveau
              collaborateur de Ouihelp.
            </Text>
          </Stack>
        </Stack>

        {isSubmitSuccessful ? (
          <Alert.Root
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            borderRadius="md"
          >
            <Alert.Indicator boxSize="40px" mr={0} />
            <Stack gap="4">
              <Alert.Title mt={4} mb={1} fontSize="lg">
                Onboarding bien reçu !
              </Alert.Title>
              <Alert.Description fontSize={"sm"}>
                La suite se passe dans ta boîte mail, tu peux retrouver le mail de demande
                d'onboarding dans ta boîte d'envoi sur GMail. Oui, c'est magique.
              </Alert.Description>
              <Alert.Description fontSize={"sm"}>
                On souhaite un super accueil à ce nouveau Ouihelper !
              </Alert.Description>
            </Stack>
          </Alert.Root>
        ) : (
          <form
            onSubmit={handleSubmit(d =>
              onboardingFetch(d).then(r => {
                if (r.status >= 400) {
                  setError("root.server", {
                    type: "server",
                    message: `Server answered ${r.status}`,
                  });
                }
              })
            )}
          >
            <Stack gap="8">
              <Stack
                gap="6"
                borderRadius="md"
                textStyle="sm"
                borderColor="blackAlpha.300"
                borderWidth={1}
                p={4}
              >
                <Heading size="xs">Données perso</Heading>

                <FormStepCustom
                  label="Prénom"
                  input={<Input type="text" {...register("firstName")} />}
                  error={errors.firstName}
                  helpText={
                    <>
                      Il s'agit du prénom <b>d'usage</b>, c'est à dire le prénom par lequel ce
                      collaborateur sera connu chez Ouihelp. C'est mieux si ça ressemble à son
                      prénom de naissance.
                    </>
                  }
                />

                <FormStepCustom
                  label="Nom"
                  input={<Input type="text" {...register("lastName")} />}
                  error={errors.lastName}
                  helpText={
                    <>
                      Pareil, c'est le nom <b>d'usage</b>.
                    </>
                  }
                />

                <Field
                  label={"Mail pro"}
                  helperText={"Construit automatiquement à partir des prénoms/noms d'usage."}
                >
                  <Input type="text" disabled value={makeMail(firstName, lastName)} />
                </Field>

                <FormStepCustom
                  label="Téléphone perso"
                  input={<Input type="text" {...register("personalPhone")} />}
                  error={errors.personalPhone}
                />

                <FormStepCustom
                  label="Mail perso"
                  input={<Input type="text" {...register("personalMail")} />}
                  error={errors.personalMail}
                />

                <Text textStyle="sm" color="fg.muted">
                  Une erreur dans cette section serait <b>particulièrement</b> préjudiciable, une
                  double (ou triple) vérification s'impose. Pour info le taux d'erreur chez Ouihelp
                  est de ~5%, fais mieux 👌🏼.
                </Text>
              </Stack>

              <Stack
                gap="6"
                borderRadius="md"
                textStyle="sm"
                borderColor="blackAlpha.300"
                borderWidth={1}
                p={4}
              >
                <Heading size="xs">Données métier</Heading>

                <FormStepCustom
                  label="Date d'arrivée (premier jour)"
                  input={<Input type="date" {...register("firstDayDate")} />}
                  error={errors.firstDayDate}
                />

                <FormStepCustom
                  label="Job / Équipe"
                  input={
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <AsyncSelect
                          isClearable={true}
                          cacheOptions
                          defaultOptions
                          loadOptions={() => groupedJobOptionsPromises}
                          ref={ref}
                          onChange={(option: JobType | null) =>
                            onChange(option ? option.value : null)
                          }
                          onBlur={onBlur}
                          value={
                            AppConfig.jobs && value
                              ? AppConfig.jobs.find(option => option.value === value)
                              : null
                          }
                        />
                      )}
                      name="job"
                      control={control}
                    />
                  }
                  error={errors.job}
                />

                {isJobOther ? (
                  <FormStepCustom
                    label="Précision sur le poste"
                    input={<Input type="text" {...register("jobOther")} />}
                    error={errors.jobOther}
                  />
                ) : null}

                <FormStepCustom
                  label="Agence(s)"
                  input={
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <AsyncSelect
                          isClearable={true}
                          cacheOptions
                          defaultOptions
                          loadOptions={() =>
                            AppConfig.staticDataPromise.then(() => AppConfig.agenciesOptions)
                          }
                          ref={ref}
                          onChange={(option: MultiValue<AgencyOptionType> | null) =>
                            onChange(option ? option.map(o => o.value) : null)
                          }
                          onBlur={onBlur}
                          value={
                            AppConfig.agenciesOptions && value
                              ? AppConfig.agenciesOptions.filter(option =>
                                  value.includes(option.value)
                                )
                              : null
                          }
                          isMulti
                        />
                      )}
                      name="agencies"
                      control={control}
                    />
                  }
                  error={errors.agencies}
                />
              </Stack>

              <Stack
                gap="6"
                borderRadius="md"
                textStyle="sm"
                borderColor="blackAlpha.300"
                borderWidth={1}
                p={4}
              >
                <Heading size="xs">Matériels & Licences</Heading>

                <FormStepCustom
                  label="Tablette pro"
                  input={
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <RadioGroup
                          ref={ref}
                          name="withTablet"
                          onValueChange={e => {
                            onChange(e.value === "yes" ? true : false);
                          }}
                          onBlur={onBlur}
                          value={value ? "yes" : "no"}
                        >
                          <Stack direction="row">
                            <Radio value="yes">Oui</Radio>
                            <Radio value="no">Non</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      name="withTablet"
                      control={control}
                    />
                  }
                  error={errors.withTablet}
                />

                <FormStepCustom
                  label="Licence Ximi"
                  input={
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <RadioGroup
                          ref={ref}
                          name="withXimi"
                          onValueChange={e => {
                            onChange(e.value === "yes" ? true : false);
                          }}
                          onBlur={onBlur}
                          value={value ? "yes" : "no"}
                        >
                          <Stack direction="row">
                            <Radio value="yes">Oui</Radio>
                            <Radio value="no">Non</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      name="withXimi"
                      control={control}
                    />
                  }
                  error={errors.withXimi}
                />

                <FormStepCustom
                  label="Téléphone pro"
                  input={
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <RadioGroup
                          ref={ref}
                          name="withPhone"
                          onValueChange={e => {
                            onChange(e.value === "yes" ? true : false);
                          }}
                          onBlur={onBlur}
                          value={value ? "yes" : "no"}
                        >
                          <Stack direction="row">
                            <Radio value="yes">Oui</Radio>
                            <Radio value="no">Non</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      name="withPhone"
                      control={control}
                    />
                  }
                  error={errors.withPhone}
                />

                <FormStepCustom
                  label="Carte SIM pour tablette"
                  input={
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <RadioGroup
                          ref={ref}
                          name="withData"
                          onValueChange={e => {
                            onChange(e.value === "yes" ? true : false);
                          }}
                          onBlur={onBlur}
                          value={value ? "yes" : "no"}
                        >
                          <Stack direction="row">
                            <Radio value="yes">Oui</Radio>
                            <Radio value="no">Non</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                      name="withData"
                      control={control}
                    />
                  }
                  error={errors.withData}
                />
              </Stack>

              <Stack
                gap="6"
                borderRadius="md"
                textStyle="sm"
                borderColor="blackAlpha.300"
                borderWidth={1}
                p={4}
              >
                <Heading size="xs">Logistique</Heading>

                <FormStepCustom
                  label="Adresse de livraison"
                  input={<Textarea {...register("deliveryAddress")} />}
                  error={errors.deliveryAddress}
                  helpText="On ne badine pas avec les détails (nom de résidence, numéro de batiment, etc) histoire d'être sûr que le livreur trouve."
                />

                <FormStepCustom
                  label="Contact de livraison"
                  input={<Textarea {...register("deliveryContact")} />}
                  error={errors.deliveryContact}
                  helpText="Idéalement avec prénom, nom, adresse mail et numéro de téléphone."
                />
              </Stack>

              <Stack
                gap="6"
                borderRadius="md"
                textStyle="sm"
                borderColor="blackAlpha.300"
                borderWidth={1}
                p={4}
              >
                <Heading size="xs">Autre</Heading>

                <FormStepCustom
                  label="Un commentaire peut-être ?"
                  input={<Textarea {...register("more")} />}
                  error={errors.more}
                />
              </Stack>

              <Button variant="subtle" loading={isSubmitting} type="submit">
                Envoyer la demande
              </Button>

              {errors?.root?.server ? (
                <Text textStyle="sm" color="red.600" textAlign="center">
                  Il y a eu une erreur dans la communication avec le serveur, veuillez ré-essayer
                  (maintenant ou plus tard...).
                </Text>
              ) : null}

              <Text textStyle="sm" color="fg.muted" textAlign="center">
                <b>Que se passe-t-il ensuite ?</b> Un mail récapitulant ta demande va être envoyé à
                Perenne IT (le prestataire de Ouihelp qui s'occupe des onboardings). Tu peux
                retrouver ce mail dans ta boîte d'envoi (sur Gmail), la suite des échanges sur cette
                demande se faisant par ce biais (le mail).
              </Text>
            </Stack>
          </form>
        )}
      </Stack>

      <DevTool control={control} />
    </Container>
  );
};

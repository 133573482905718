import { useGetJobs } from "@/services/2/hohishes/queries";

export const JobName = ({ job }: { job: string | null }) => {
  const { data } = useGetJobs();
  const foundJob = data?.find(j => j.value === job);
  if (!foundJob) {
    return null;
  }
  return <span>{foundJob.label}</span>;
};

import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";

import { persistBaseOptions, queryClient } from "@/services/2/react-query";

export const ReactQueryWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}: React.PropsWithChildren) => (
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{
      ...persistBaseOptions,
      dehydrateOptions: {
        shouldDehydrateQuery: query => !query.queryKey.includes("uncached"),
      },
    }}
  >
    {children}
  </PersistQueryClientProvider>
);

import { Stack, Heading, SimpleGrid } from "@chakra-ui/react";

import { DataListItem, DataListRoot } from "@/codegen/ui/data-list";

import { Territories } from "@/components/2/geo";
import { EnrichedHohishType } from "@/services/2/hohishes/types";
import { JobName } from "@/components/2/hohishes";
import { useEnrichedHohishOutletContext } from "./local-context";

interface FieldType {
  field: keyof EnrichedHohishType;
  valueGenerator?: (data: EnrichedHohishType) => React.ReactNode;
  label: string;
  skipIfNullish?: boolean;
}

const identityFields: FieldType[] = [
  { field: "first_name", label: "Prénom" },
  { field: "last_name", label: "Nom" },
  { field: "primary_mail_address", label: "Adresse mail" },
];

const currentJobFields: FieldType[] = [
  {
    field: "job2",
    valueGenerator: (h: EnrichedHohishType) => <JobName job={h.job2} />,
    label: "Poste actuel",
  },
  {
    field: "territoryUuids",
    valueGenerator: (h: EnrichedHohishType) => <Territories territoryUuids={h.territoryUuids} />,
    label: "Territoire",
  },
  { field: "starts_on2", label: "Premier jour poste actuel" },
  { field: "ends_on2", label: "Dernier jour poste actuel", skipIfNullish: true },
];

const withOuihelpFields: FieldType[] = [
  { field: "first_day_date", label: "Premier jour Ouihelp" },
  { field: "last_day_date", label: "Dernier jour Ouihelp", skipIfNullish: true },
];

const idsFields: FieldType[] = [
  { field: "uuid", label: "ID DSIT" },
  { field: "microsoft_id", label: "ID Microsoft" },
  { field: "google_id", label: "ID Google" },
  { field: "slack_id", label: "ID Slack" },
  { field: "aircall_id", label: "ID Aircall" },
];

const CustomDataList = ({ fields, data }: { fields: FieldType[]; data: EnrichedHohishType }) => (
  <DataListRoot gap={2} variant={"bold"}>
    {fields.map(({ field, label, valueGenerator, skipIfNullish }) =>
      skipIfNullish && field && !data[field] ? null : (
        <DataListItem
          key={field}
          label={label}
          value={valueGenerator ? valueGenerator(data) : data[field]}
        />
      )
    )}
  </DataListRoot>
);

export const ViewHohish = () => {
  const hohish = useEnrichedHohishOutletContext();

  return (
    <SimpleGrid columns={2} gap={8} minChildWidth={"sm"}>
      <Stack gap={2}>
        <Heading size="md">Identité</Heading>

        <CustomDataList fields={identityFields} data={hohish} />
      </Stack>

      <Stack gap={2}>
        <Heading size="md">Affectation</Heading>

        <CustomDataList fields={currentJobFields} data={hohish} />
      </Stack>

      <Stack gap={2}>
        <Heading size="md">Chez Ouihelp</Heading>

        <CustomDataList fields={withOuihelpFields} data={hohish} />
      </Stack>

      <Stack gap={2}>
        <Heading size="md">Identifiants</Heading>

        <CustomDataList fields={idsFields} data={hohish} />
      </Stack>
    </SimpleGrid>
  );
};

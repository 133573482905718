import { useState } from "react";
import { useNavigate } from "react-router";
import MultiSelect from "react-select";
import { Title, Typography } from "../../../components/Typography";
import { useGetAllDepartments } from "../../../services/department";
import {
  FinancialHelpType,
  GeoAreaType,
  postFinancialHelp,
} from "../../../services/financial-help";
import { GeoAreaLabel, HelpLabel } from "../../../services/financial-help/translation";
import { useGetAllLegalEntities } from "../../../services/legal-entity";
import { DEFAULT_FINANCIAL_HELP_CREATE_DATA } from "./constants";
import { FinancialHelpCreateData } from "./types";
import { HELP_SCHEMA } from "./validator";

export const CreateFinancialHelp = () => {
  const [help, setHelp] = useState(DEFAULT_FINANCIAL_HELP_CREATE_DATA);
  const [submitted, setSubmitted] = useState(false);
  const [departments] = useGetAllDepartments();
  const [legalEntities] = useGetAllLegalEntities();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // navigation handlers
  const navigate = useNavigate();

  // handle loadings and errors
  if (departments.isLoading || legalEntities.isLoading) return <div>Chargement...</div>;
  if (departments.isError || legalEntities.isError) return <div>Une erreur est survenue :(</div>;

  // options
  const departmentOptions = departments.data.map(d => ({
    label: d.name,
    value: d.id,
  }));
  const legalEntitiesOptions = legalEntities.data.map(d => ({
    label: d.name,
    value: d.uuid,
  }));

  // handle data validation
  const parsedData = HELP_SCHEMA.safeParse(help);
  const errors = !parsedData.success
    ? Object.fromEntries(parsedData.error.errors.map(err => [err.path.join("."), err.message]))
    : {};
  const shownErrors = submitted ? errors : {};

  // handle form data
  const handleChange =
    <K extends keyof FinancialHelpCreateData>(key: K) =>
    (value: FinancialHelpCreateData[K]) => {
      setHelp(prev => ({ ...prev, [key]: value }));
    };

  const handleSubmit = () => {
    setSubmitted(true);
    if (parsedData.success) {
      setIsLoading(true);
      setIsError(false);
      postFinancialHelp(parsedData.data)
        .then(() => navigate("/financial-helps"))
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <form
      style={{
        borderRadius: 10,
        boxShadow: "0 0 3px 1px rgb(200,200,200)",
        margin: "auto",
        padding: 32,
        boxSizing: "border-box",
        maxWidth: 850,
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Title type="h1">Création d'aide financière</Title>
      {isError && <Typography color="red">Y'a eu un soucis :'(</Typography>}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>Nom</label>
        <input onChange={e => handleChange("name")(e.target.value)} value={help.name} />
        <Typography color="red">{shownErrors.name}</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>Description</label>
        <textarea
          onChange={e => handleChange("description")(e.target.value)}
          value={help.description}
          style={{ width: "100%", minHeight: 100 }}
        />
        <Typography color="red">{shownErrors.description}</Typography>
      </div>
      <div style={{ display: "flex", gap: 16 }}>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>Type d'aide</label>
          <select
            onChange={e => handleChange("help_type")(e.target.value as FinancialHelpType)}
            value={help.help_type || ""}
          >
            <option value="">Selectionnez une valeur...</option>
            {Object.entries(HelpLabel).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <Typography color="red">{shownErrors.help_type}</Typography>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>Portée géographique</label>
          <select
            onChange={e => handleChange("geo_area_type")(e.target.value as GeoAreaType)}
            value={help.geo_area_type || ""}
          >
            <option value="">Selectionnez une valeur...</option>
            {Object.entries(GeoAreaLabel).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <Typography color="red">{shownErrors.geo_area_type}</Typography>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>Entité</label>
          <select
            onChange={e => handleChange("legal_entity_uuid")(e.target.value)}
            value={help.legal_entity_uuid || ""}
          >
            <option value="">Selectionnez une valeur...</option>
            {legalEntitiesOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <Typography color="red">{shownErrors.legal_entity_uuid}</Typography>
        </div>
      </div>
      {help.geo_area_type === GeoAreaType.DEPARTMENTAL && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Departements</label>
          <MultiSelect
            isMulti
            value={departmentOptions.filter(opt => help.departments.includes(opt.value))}
            options={departmentOptions}
            onChange={value =>
              setHelp(prev => ({
                ...prev,
                departments: value.map(v => v.value),
              }))
            }
          />
        </div>
      )}
      <div style={{ display: "flex", gap: 16 }}>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>GIR minimum</label>
          <select
            onChange={e => handleChange("min_gir")(e.target.value ? +e.target.value : null)}
            value={help.min_gir || ""}
          >
            <option value="">Selectionnez une valeur...</option>
            {Array(6)
              .fill(null)
              .map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
          </select>
          <Typography color="red">{shownErrors.min_gir}</Typography>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>GIR maximum</label>
          <select
            onChange={e => handleChange("max_gir")(e.target.value ? +e.target.value : null)}
            value={help.max_gir || ""}
          >
            <option value="">Selectionnez une valeur...</option>
            {Array(6)
              .fill(null)
              .map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
          </select>
          <Typography color="red">{shownErrors.max_gir}</Typography>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>Taux de prise en charge maximum</label>
          <input
            onChange={e => handleChange("max_care_rate")(e.target.value ? e.target.value : null)}
            type="number"
            value={help.max_care_rate || ""}
          />
          <Typography color="red">{shownErrors.max_care_rate}</Typography>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>URL slite</label>
        <input
          onChange={e => handleChange("slite_url")(e.target.value)}
          width="100%"
          type="text"
          value={help.slite_url}
        />
        <Typography color="red">{shownErrors.slite_url}</Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>Sous forme de tiers payant</label>
        <select
          onChange={e => handleChange("is_third_party_payer")(e.target.value === "true")}
          value={help.is_third_party_payer.toString() || ""}
        >
          <option value="">Selectionnez une valeur...</option>
          <option value="true">Oui</option>
          <option value="false">Non</option>
        </select>
      </div>
      <div style={{ display: "flex", gap: 16 }}>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>Montant horaire pris en charge en semaine</label>
          <input
            onChange={e =>
              handleChange("week_amount_per_hour_cents")(e.target.value ? e.target.value : null)
            }
            type="number"
            value={help.week_amount_per_hour_cents || ""}
          />
          <Typography color="red">{shownErrors.week_amount_per_hour_cents}</Typography>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>Montant horaire pris en charge le samedi</label>
          <input
            onChange={e =>
              handleChange("saturday_amount_per_hour_cents")(e.target.value ? e.target.value : null)
            }
            type="number"
            value={help.saturday_amount_per_hour_cents || ""}
          />
          <Typography color="red">{shownErrors.saturday_amount_per_hour_cents}</Typography>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <label>Montant horaire pris en charge le dimanche</label>
          <input
            onChange={e =>
              handleChange("sunday_amount_per_hour_cents")(e.target.value ? e.target.value : null)
            }
            type="number"
            value={help.sunday_amount_per_hour_cents || ""}
          />
          <Typography color="red">{shownErrors.sunday_amount_per_hour_cents}</Typography>
        </div>
      </div>
      <button
        disabled={isLoading}
        onClick={handleSubmit}
        style={{ width: 300, margin: "auto", height: 40 }}
      >
        Envoyer {isLoading && "chargement..."}
      </button>
    </form>
  );
};

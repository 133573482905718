import { Stack, Heading, SimpleGrid } from "@chakra-ui/react";

import { DataListItem, DataListRoot } from "@/codegen/ui/data-list";

import { EnrichedHohishType } from "@/services/2/hohishes/types";
import { useEnrichedHohishOutletContext } from "./local-context";

interface FieldType {
  field: keyof EnrichedHohishType;
  valueGenerator?: (data: EnrichedHohishType) => React.ReactNode;
  label: string;
  skipIfNullish?: boolean;
}

const identityFields: FieldType[] = [
  { field: "first_name", label: "Prénom" },
  { field: "last_name", label: "Nom" },
  { field: "primary_mail_address", label: "Adresse mail" },
];

const idsFields: FieldType[] = [
  { field: "uuid", label: "ID DSIT" },
  { field: "microsoft_id", label: "ID Microsoft" },
  { field: "google_id", label: "ID Google" },
  { field: "slack_id", label: "ID Slack" },
  { field: "aircall_id", label: "ID Aircall" },
];

const CustomDataList = ({ fields, data }: { fields: FieldType[]; data: EnrichedHohishType }) => (
  <DataListRoot gap={2} variant={"bold"}>
    {fields.map(({ field, label, valueGenerator, skipIfNullish }) =>
      skipIfNullish && field && !data[field] ? null : (
        <DataListItem
          key={field}
          label={label}
          value={valueGenerator ? valueGenerator(data) : data[field]}
        />
      )
    )}
  </DataListRoot>
);

export const IdentityHohish = () => {
  const hohish = useEnrichedHohishOutletContext();

  return (
    <SimpleGrid columns={2} gap={8} minChildWidth={"sm"}>
      <Stack gap={2}>
        <Heading size="md">Identité</Heading>

        <CustomDataList fields={identityFields} data={hohish} />
      </Stack>

      <Stack gap={2}>
        <Heading size="md">Identifiants</Heading>

        <CustomDataList fields={idsFields} data={hohish} />
      </Stack>
    </SimpleGrid>
  );
};

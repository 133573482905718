import { PiDotsThree, PiDotsNine, PiDotsSix } from "react-icons/pi";
import { IconType } from "react-icons/lib";

import {
  useGetAgenciesGeographiesAgenciesGet,
  useGetMutualizationAreasGeographiesMutualizationAreasGet,
  useGetRegionsGeographiesRegionsGet,
} from "@/codegen/openapi/queries";

const InnerTerritoryName = ({ Icon, name }: { Icon: IconType; name: string }) => {
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <Icon style={{ display: "inline" }} /> {name}
    </span>
  );
};

const AgencyComponent = ({ name }: { name: string }) => (
  <InnerTerritoryName Icon={PiDotsThree} name={name} />
);
const MutualizationAreaComponent = ({ name }: { name: string }) => (
  <InnerTerritoryName Icon={PiDotsSix} name={name} />
);
const RegionComponent = ({ name }: { name: string }) => (
  <InnerTerritoryName Icon={PiDotsNine} name={name} />
);

export const MutualizationAreaName = ({
  mutualizationAreaUuid,
}: {
  mutualizationAreaUuid: string | null;
}) => {
  const { data } = useGetMutualizationAreasGeographiesMutualizationAreasGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });

  const mutArea = data?.find(ma => ma.uuid === mutualizationAreaUuid);
  if (mutArea) {
    return <MutualizationAreaComponent name={mutArea.name} />;
  }
  return null;
};

export const RegionName = ({ regionUuid }: { regionUuid: string }) => {
  const { data } = useGetRegionsGeographiesRegionsGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });

  const region = data?.find(r => r.uuid === regionUuid);
  if (region) {
    return <RegionComponent name={region.name} />;
  }
  return null;
};

export const TerritoryName = ({ territoryUuid }: { territoryUuid: string }) => {
  const { data: agencyData } = useGetAgenciesGeographiesAgenciesGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
  const { data: mutAreaData } = useGetMutualizationAreasGeographiesMutualizationAreasGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
  const { data: regionData } = useGetRegionsGeographiesRegionsGet({}, [], {
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });

  const agency = agencyData?.find(a => a.uuid === territoryUuid);
  if (agency) {
    return <AgencyComponent name={agency.name} />;
  }
  const mutArea = mutAreaData?.find(ma => ma.uuid === territoryUuid);
  if (mutArea) {
    return <MutualizationAreaComponent name={mutArea.name} />;
  }
  const region = regionData?.find(r => r.uuid === territoryUuid);
  if (region) {
    return <RegionComponent name={region.name} />;
  }
  return null;
};

export const Territories = ({ territoryUuids }: { territoryUuids: string[] }) => {
  const territoriesJSX = territoryUuids.map(t => <TerritoryName key={t} territoryUuid={t} />);
  return territoriesJSX.flatMap((e, i) => [e, <span key={i}>, </span>]).slice(0, -1);
};

import { RefObject, useEffect, useRef, useState } from "react";

export const useResizeObserver = (): [RefObject<HTMLTableElement>, DOMRect | undefined] => {
  const ref = useRef<HTMLTableElement>(null);
  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new ResizeObserver(() => {
      if (ref.current) {
        const boundingRect = ref.current.getBoundingClientRect();
        setRect(boundingRect);
      }
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref]);

  return [ref, rect];
};

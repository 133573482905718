import { type Options } from "@hey-api/client-fetch";

import {
  getHohishesHohishesGet,
  GetHohishesHohishesGetData,
  getHohishHohishesHohishUuidGet,
  GetHohishHohishesHohishUuidGetData,
  getJobsHohishesJobsGet,
  getPostingsHohishesHohishUuidPostingsGet,
  GetPostingsHohishesHohishUuidPostingsGetData,
  JobType,
} from "@/codegen/openapi/requests";
import { UseGetJobsHohishesJobsGetKeyFn } from "@/codegen/openapi/queries";

import { TWENTY_FOUR_STALE_WHILE_REVALIDATE, queryClient } from "../react-query";
import { EnrichedHohishPostingType, EnrichedHohishType } from "./types";

// Jobs
export const getJobsQueryFn = (): Promise<JobType[]> =>
  getJobsHohishesJobsGet({}).then(response => response.data as JobType[]);

export const getJobs = () =>
  queryClient.fetchQuery({
    queryKey: UseGetJobsHohishesJobsGetKeyFn(),
    queryFn: getJobsQueryFn,
    ...TWENTY_FOUR_STALE_WHILE_REVALIDATE,
  });

interface JobGroup {
  label: string;
  jobs: JobType[];
}

const groupOrder: Record<any, number> = {
  Ops: 0,
  Sales: 1,
  Autres: 2,
};

export const getJobGroups = (): Promise<JobGroup[]> =>
  getJobs().then(jobs => {
    const jobGroups: Record<string, JobGroup> = {};
    jobs.forEach(job => {
      if (!job.assignable) {
        return;
      }

      if (!jobGroups[job.group]) {
        jobGroups[job.group] = {
          label: job.group,
          jobs: [],
        };
      }

      jobGroups[job.group].jobs.push(job);
    });

    return Object.values(jobGroups).sort((a, b) => groupOrder[a.label] - groupOrder[b.label]);
  });

// Multiple hohishes
export const getEnrichedHohishesQueryFn = async (
  clientOptions: Options<GetHohishesHohishesGetData, true>
): Promise<EnrichedHohishType[]> => {
  const naive = await getHohishesHohishesGet({ ...clientOptions }).then(response => response.data);
  return naive.map(n => ({
    ...n,
    territoryUuids: ([] as string[]).concat(
      n.agency_uuids2,
      n.mutualization_area_uuids2,
      n.region_uuids2
    ),
  }));
};

// Single hohish
export const getEnrichedHohishQueryFn = async (
  clientOptions: Options<GetHohishHohishesHohishUuidGetData, true>
): Promise<EnrichedHohishType> => {
  const naive = await getHohishHohishesHohishUuidGet({ ...clientOptions }).then(
    response => response.data
  );
  return {
    ...naive,
    territoryUuids: ([] as string[]).concat(
      naive.agency_uuids2,
      naive.mutualization_area_uuids2,
      naive.region_uuids2
    ),
  };
};

export const getEnrichedHohishPostingsQueryFn = async (
  clientOptions: Options<GetPostingsHohishesHohishUuidPostingsGetData, true>
): Promise<EnrichedHohishPostingType[]> => {
  const naive = await getPostingsHohishesHohishUuidPostingsGet({ ...clientOptions }).then(
    response => response.data
  );
  return naive.map(n => ({
    ...n,
    territoryUuids: ([] as string[]).concat(
      n.agency_uuids,
      n.mutualization_area_uuids,
      n.region_uuids
    ),
  }));
};

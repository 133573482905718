import { createColumnHelper } from "@tanstack/react-table";
import { Flex, Stack, Link as ChakraLink } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router";

import { TableLayout } from "@/components/2/layouts/table";
import { Territories } from "@/components/2/geo";
import { useGetEnrichedHohishPostings } from "@/services/2/hohishes/queries";
import { EnrichedHohishPostingType } from "@/services/2/hohishes/types";
import { JobName } from "@/components/2/hohishes";
import { Button } from "@/codegen/ui/button";

import { useEnrichedHohishOutletContext } from "../local-context";

const columnHelper = createColumnHelper<EnrichedHohishPostingType>();

const columns = [
  columnHelper.accessor("job", {
    header: "Poste",
    cell: ({ cell }) => <JobName job={cell.getValue()} />,
    size: 220,
  }),
  columnHelper.accessor("type", {
    header: "Type",
    cell: ({ cell }) => (cell.getValue() === "permanent" ? "Permanente" : "Backup"),
    size: 140,
  }),
  columnHelper.accessor("starts_on", {
    header: "Premier jour",
    minSize: 120,
    size: 120,
  }),
  columnHelper.accessor("ends_on", {
    header: "Dernier jour",
    minSize: 120,
    size: 120,
  }),
  columnHelper.accessor("territoryUuids", {
    header: "Territoire(s)",
    cell: ({ cell }) => <Territories territoryUuids={cell.getValue()} />,
  }),
  columnHelper.display({
    header: "Actions",
    cell: ({ row }) => (
      <RouterLink to={`${row.original.uuid!}/edit`} style={{ textDecoration: "underline" }}>
        <ChakraLink variant="underline" colorPalette={"blue"} as="span">
          Modifier
        </ChakraLink>
      </RouterLink>
    ),
    size: 100,
  }),
];

export const PostingsHohish = () => {
  const hohish = useEnrichedHohishOutletContext();
  const navigate = useNavigate();
  const { data } = useGetEnrichedHohishPostings(
    { path: { hohish_uuid: hohish.uuid } },
    ["uncached"],
    {
      placeholderData: [],
    }
  );
  return (
    <Stack gap={4}>
      <Flex justify="flex-end" gap={4}>
        <Button onClick={() => navigate("create")}>Nouvelle affectation</Button>
      </Flex>
      <TableLayout data={data as EnrichedHohishPostingType[]} columns={columns} />
    </Stack>
  );
};

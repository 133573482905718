import {
  UseGetAgenciesGeographiesAgenciesGetKeyFn,
  UseGetMutualizationAreasGeographiesMutualizationAreasGetKeyFn,
  UseGetRegionsGeographiesRegionsGetKeyFn,
} from "@/codegen/openapi/queries";
import {
  AgencyType,
  getAgenciesGeographiesAgenciesGet,
  getMutualizationAreasGeographiesMutualizationAreasGet,
  getRegionsGeographiesRegionsGet,
  MutualizationAreaType,
  RegionType,
} from "@/codegen/openapi/requests";
import { queryClient, TWENTY_FOUR_STALE_WHILE_REVALIDATE } from "../react-query";
import { TerritoriesType } from "./types";
import { useMemo } from "react";

const getAgenciesQueryFn = (): Promise<AgencyType[]> =>
  getAgenciesGeographiesAgenciesGet({}).then(response => response.data as AgencyType[]);

export const getAgencies = () => {
  return queryClient.fetchQuery({
    ...TWENTY_FOUR_STALE_WHILE_REVALIDATE,
    queryKey: UseGetAgenciesGeographiesAgenciesGetKeyFn(),
    queryFn: getAgenciesQueryFn,
  });
};

const getMutualizationAreasQueryFn = (): Promise<MutualizationAreaType[]> =>
  getMutualizationAreasGeographiesMutualizationAreasGet({}).then(
    response => response.data as MutualizationAreaType[]
  );

export const getMutualizationAreas = () => {
  return queryClient.fetchQuery({
    queryKey: UseGetMutualizationAreasGeographiesMutualizationAreasGetKeyFn(),
    queryFn: getMutualizationAreasQueryFn,
    ...TWENTY_FOUR_STALE_WHILE_REVALIDATE,
  });
};

const getRegionsQueryFn = (): Promise<RegionType[]> =>
  getRegionsGeographiesRegionsGet({}).then(response => response.data as RegionType[]);

export const getRegions = () => {
  return queryClient.fetchQuery({
    queryKey: UseGetRegionsGeographiesRegionsGetKeyFn(),
    queryFn: getRegionsQueryFn,
    ...TWENTY_FOUR_STALE_WHILE_REVALIDATE,
  });
};

export const getTerritories = async (): Promise<TerritoriesType> =>
  Promise.all([getAgencies(), getMutualizationAreas(), getRegions()]).then(
    ([agencies, mutualizationAreas, regions]) => {
      return {
        agencies,
        mutualizationAreas,
        regions,
      };
    }
  );

export const useTerritoriesPromise = (): Promise<TerritoriesType> => {
  return useMemo(() => getTerritories(), []);
};

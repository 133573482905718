import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";

const ONE_HOURS_IN_MS = 1000 * 60 * 60;
const TWENTY_FOUR_HOURS_IN_MS = 24 * ONE_HOURS_IN_MS;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: TWENTY_FOUR_HOURS_IN_MS,
      staleTime: 0,
      placeholderData: undefined,
    },
  },
});

type TwentyFourHourseStaleWhileRevalidate = {
  staleTime: number;
  placeholderData: (prev: any) => any;
};

export const TWENTY_FOUR_STALE_WHILE_REVALIDATE: TwentyFourHourseStaleWhileRevalidate = {
  staleTime: TWENTY_FOUR_HOURS_IN_MS,
  placeholderData: (prev: any) => prev,
};

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const persistBaseOptions = {
  persister,
  maxAge: TWENTY_FOUR_HOURS_IN_MS,
};

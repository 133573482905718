import { type Options } from "@hey-api/client-fetch";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  GetHohishesHohishesGetData,
  GetHohishHohishesHohishUuidGetData,
  GetPostingsHohishesHohishUuidPostingsGetData,
  HTTPValidationError,
} from "@/codegen/openapi/requests/types.gen";
import { UseGetJobsHohishesJobsGetKeyFn } from "@/codegen/openapi/queries/common";

import { TWENTY_FOUR_STALE_WHILE_REVALIDATE } from "../react-query";

import { EnrichedHohishPostingType, EnrichedHohishType } from "./types";
import {
  getEnrichedHohishesQueryFn,
  getEnrichedHohishPostingsQueryFn,
  getEnrichedHohishQueryFn,
  getJobsQueryFn,
} from "./standalone-fetches";

// Jobs
export const useGetJobs = () =>
  useQuery({
    queryKey: UseGetJobsHohishesJobsGetKeyFn(),
    queryFn: getJobsQueryFn,
    ...TWENTY_FOUR_STALE_WHILE_REVALIDATE,
  });

// Multiple hohishes
export const useGetEnrichedHohishes = (
  clientOptions: Options<GetHohishesHohishesGetData, true>,
  queryKey: string[],
  options?: Omit<UseQueryOptions<EnrichedHohishType[], HTTPValidationError>, "queryKey" | "queryFn">
) =>
  useQuery<EnrichedHohishType[], HTTPValidationError>({
    queryKey: [
      "GetEnrichedHohishes",
      clientOptions.query?.legal_entity_uuids || "all",
      ...queryKey,
    ],
    queryFn: () => getEnrichedHohishesQueryFn(clientOptions),
    ...options,
  });

// Single hohish
export const useGetEnrichedHohish = (
  clientOptions: Options<GetHohishHohishesHohishUuidGetData, true>,
  queryKey: string[]
) =>
  useQuery<EnrichedHohishType, HTTPValidationError>({
    queryKey: ["GetEnrichedHohish", clientOptions.path.hohish_uuid, ...queryKey],
    queryFn: () => getEnrichedHohishQueryFn(clientOptions),
  });

export const useGetEnrichedHohishPostings = (
  clientOptions: Options<GetPostingsHohishesHohishUuidPostingsGetData, true>,
  queryKey: string[],
  options?: Omit<
    UseQueryOptions<EnrichedHohishPostingType[], HTTPValidationError>,
    "queryKey" | "queryFn"
  >
) =>
  useQuery<EnrichedHohishPostingType[], HTTPValidationError>({
    queryKey: ["GetEnrichedHohishPostings", clientOptions.path.hohish_uuid, ...queryKey],
    queryFn: () => getEnrichedHohishPostingsQueryFn(clientOptions),
    ...options,
  });

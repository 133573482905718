import { useMemo } from "react";
import { Stack, Box, Heading, Link } from "@chakra-ui/react";

import { Tabs } from "@chakra-ui/react";
import { useParams, Outlet, useLocation, useNavigate } from "react-router";

import { useGetEnrichedHohish } from "@/services/2/hohishes/queries";

export const SingleLayoutHohish = () => {
  const { hohishUuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useMemo(() => {
    const fragments = location.pathname.split("/");

    for (const f of ["identity", "postings"]) {
      if (fragments.includes(f)) {
        return f;
      }
    }

    return "abstract";
  }, [location.pathname]);

  if (!hohishUuid) {
    throw new Error("No hohishUuid provided");
  }

  const { data } = useGetEnrichedHohish({ path: { hohish_uuid: hohishUuid } }, ["uncached"]);

  if (!data) {
    return null;
  }

  return (
    <Stack gap={0}>
      <Box px={8} pt={8} backgroundColor={"gray.100"}>
        <Stack gap={4}>
          <Heading size="2xl">
            {data.first_name} {data.last_name}
          </Heading>

          <Tabs.Root value={activeTab}>
            <Tabs.List borderBottomColor={"transparent"}>
              <Tabs.Trigger value="abstract" asChild>
                <Link onClick={() => navigate("")} unstyled>
                  Résumé
                </Link>
              </Tabs.Trigger>
              <Tabs.Trigger value="identity" asChild>
                <Link onClick={() => navigate("identity")} unstyled>
                  Identité & Liens
                </Link>
              </Tabs.Trigger>
              <Tabs.Trigger value="postings" asChild>
                <Link onClick={() => navigate("postings")} unstyled>
                  Affectations
                </Link>
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
        </Stack>
      </Box>

      <Box p={8}>
        <Outlet context={{ hohish: data }} />
      </Box>
    </Stack>
  );
};
